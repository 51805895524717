import React from 'react';
import { AdUpgradePanel } from '@common/components/upgradeJobAd';
import { useGetJobPostedSuccessLink } from '@common/hooks/appPageLinks';
import { useGetPaymentSuccessLink } from '@common/hooks/appPageLinks/useGetPaymentSuccessLink';
import { useLoadAdPrices } from '@common/hooks/payment/useLoadAdPrices/useLoadAdPrices';
import { useTranslation } from '@common/hooks/useTranslation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';

type Props = {
  jobId: string;
  jobCountryCode: SupportedCountryCode;
};

export function UpgradeJob(props: Props) {
  const { jobId, jobCountryCode } = props;
  const { t } = useTranslation();

  useLoadAdPrices(jobCountryCode);

  const getPaymentSuccessLink = useGetPaymentSuccessLink({
    jobId,
    jobCountryCode,
    fromPostJob: true
  });

  const basicRedirectLink = useGetJobPostedSuccessLink(jobId);

  return (
    <Stack paddingTop="medium" padding="large">
      <Box paddingBottom="small">
        <Heading level="h2" weight="regular">
          {t('upgradeJobPage.heading')}
        </Heading>
      </Box>

      <AdUpgradePanel
        job={{
          id: jobId,
          countryCode: jobCountryCode
        }}
        campaign={PAYMENT_CAMPAIGN.DEFAULT}
        getPaymentSuccessLink={getPaymentSuccessLink}
        basicAdLink={basicRedirectLink}
      />
    </Stack>
  );
}
