import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTrackCajaEvent } from '@common/components/Caja/events';
import { PaymentProvider } from '@common/components/FormElements/PaymentProvider';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { PricingPanel } from '@common/components/PricingPanel';
import { RequestErrorMessages } from '@common/components/RequestErrorMessage';
import { useCoinsFeature } from '@common/hooks/featureToggles';
import { useLoadJobAndAdPrices } from '@common/hooks/payment';
import { useRedirectToJobNotFoundIfError } from '@common/hooks/useRedirectToJobNotFoundIfError';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useRedirectUnupgradeableAd } from '@common/hooks/useRedirectUnupgradeableAd';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job/adType';
import CheckoutForm from '@src/joraEmployer/components/CheckoutForm/CheckoutForm';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import {
  useGetRequestErrors,
  useRedirectInvalidAdType,
  useSetupPayment,
  useVerifyPaymentCampaign
} from './hooks';

export const Payment = () => {
  useRedirectUnauthenticatedUser();
  useRedirectToJobNotFoundIfError();

  const { getQueryParam } = useSearchQueryParam();
  const adType = getQueryParam('adType') as UpgradeableAdType;
  useRedirectInvalidAdType(adType);

  const { jobId } = useParams<{ jobId: string }>();
  const { job, jobCountryCode, currencyPrice, coinPrice } =
    useLoadJobAndAdPrices(jobId);
  useRedirectUnupgradeableAd(job);

  const { t } = useTranslation();
  const campaign = useVerifyPaymentCampaign();
  const { requestKeys } = useGetRequestErrors();
  const supportCoins = useCoinsFeature(jobCountryCode);

  useSetupPayment({ campaign, jobCountryCode, jobId });

  useTrackCajaEvent({
    step: `${adType}PaymentPageViewed`,
    jobId
  });

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('paymentPage.title')} />

      <Box
        paddingTop="medium"
        paddingBottom="small"
        paddingHorizontal="medium"
        tone="transparent"
      >
        <Heading level="h1" weight="regular">
          {t('paymentPage.title')}
        </Heading>
      </Box>

      <Card>
        <CardContent>
          <Stack spacing="medium">
            <Stack spacing="xlarge">
              {supportCoins && coinPrice ? (
                <Stack spacing="small">
                  <Heading level="h2">{t('paymentPage.heading')}</Heading>

                  <PricingPanel
                    adType={adType}
                    coinPrice={coinPrice[adType]!}
                    currencyPrice={currencyPrice[adType]!}
                  />
                </Stack>
              ) : null}

              <Stack spacing="large">
                <Heading level="h2">
                  {jobCountryCode === SupportedCountryCode.Indonesia
                    ? t('checkoutForm.bankTransfer.heading')
                    : t('checkoutForm.card.heading')}
                </Heading>

                <PaymentProvider>
                  <CheckoutForm jobId={jobId} campaign={campaign} />
                </PaymentProvider>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <RequestErrorMessages requestKeys={requestKeys} />
    </Stack>
  );
};
