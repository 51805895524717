import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getResumeUrl } from '@store/entities/applications/actions';
import { getTemporaryResumeLink } from '@store/entities/applications/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export function useDownloadResumeCardAction(applicationId: string) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isDownloadRequestedRef = useRef(false);
  const temporaryResumeLink = useAppSelector(
    getTemporaryResumeLink(applicationId)
  );

  useEffect(() => {
    if (isDownloadRequestedRef.current && temporaryResumeLink) {
      isDownloadRequestedRef.current = false;

      downloadAndOpenResume(temporaryResumeLink.url);
    }
  }, [t, temporaryResumeLink]);

  const downloadResume = useCallback(async () => {
    isDownloadRequestedRef.current = true;
    await dispatch(getResumeUrl(applicationId));
  }, [applicationId, dispatch]);

  return {
    downloadResume
  };
}

function downloadAndOpenResume(url: string) {
  window.location.href = url;
}
