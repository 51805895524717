import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdUpgradePanel } from '@common/components/upgradeJobAd';
import { useGetPaymentSuccessLink } from '@common/hooks/appPageLinks/useGetPaymentSuccessLink';
import { useLoadAdPrices } from '@common/hooks/payment';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { UpgradeJobCalloutText } from './components';

type UpgradesProps = {
  job: {
    id: string;
    countryCode: SupportedCountryCode;
  };
};

export const Upgrades = ({ job }: UpgradesProps) => {
  const { id: jobId, countryCode: jobCountryCode } = job;
  const { t } = useTranslation();

  useLoadAdPrices(jobCountryCode);
  const getPaymentSuccessLink = useGetPaymentSuccessLink({
    jobId,
    jobCountryCode
  });

  return (
    <Box tone="transparent">
      <Box
        paddingHorizontal="medium"
        marginTop="large"
        marginBottom="medium"
        tone="transparent"
      >
        <Heading level="h1">
          {t('jobDetailsAndApplicationsListPage.upgrades.heading')}
        </Heading>
      </Box>

      <Card>
        <CardContent>
          <Stack spacing="medium">
            <UpgradeJobCalloutText />

            <AdUpgradePanel
              job={{
                id: jobId,
                countryCode: jobCountryCode
              }}
              campaign={PAYMENT_CAMPAIGN.CANDIDATE_MANAGEMENT}
              getPaymentSuccessLink={getPaymentSuccessLink}
            />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
