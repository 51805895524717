import { PostJobFormData } from '@common/components/Job/forms/MultiStepPostJob';
import { JobForEdit } from '@common/hooks/job';
import { BusinessWithUsers } from '@store/entities/businesses/types';

export const toEditJobFormData = (
  jobUpdates: PostJobFormData,
  job: JobForEdit,
  business?: BusinessWithUsers
): PostJobFormData => {
  const formData: PostJobFormData = {
    jobTitle: jobUpdates.jobTitle ?? {
      jobTitle: job?.title,
      role: job?.jobRole
    },
    workExperience: jobUpdates.workExperience ?? job?.workExperience,
    salary: jobUpdates.salary ?? job?.salary,
    jobType: jobUpdates.jobType ?? job?.jobType,
    shiftAvailability: jobUpdates.shiftAvailability ?? job?.shiftAvailability,
    rightToWork: jobUpdates.rightToWork ?? job?.rightToWork,
    immediateStart: jobUpdates.immediateStart ?? job?.immediateStart,
    jobDescription: jobUpdates.jobDescription ?? job?.description,
    targetLocations: jobUpdates.targetLocations ?? []
  };

  if (business) {
    formData.business = {
      id: business.id,
      name: business.name,
      geolocation: business.locations[0],
      businessSize: business.businessSize,
      recruitmentCompanyName: business.recruitmentCompanyName
    };
  }

  return formData;
};
