import React from 'react';
import { useCajaVariants } from '@common/hooks/featureToggles';
import { SignUp as DefaultSignUp } from './default/SignUp';
import { SignUp as DefaultV2SignUp } from './default_v2/SignUp';

export function SignUpRouter() {
  const cajaVariantsEnabled = useCajaVariants();

  if (!cajaVariantsEnabled) {
    //  revert back to original, this can be removed after caja has it's first variants. This is just to make sure we don't break anything
    return <DefaultSignUp />;
  }

  return <DefaultV2SignUp />;
}
