import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  type PostJobFormData,
  formVariant
} from '@common/components/Job/forms/MultiStepPostJob';
import { useBusinessState } from '@common/hooks/business';
import { useSteps } from '@common/hooks/multiStep';
import { useUpgradePermissions } from '@common/hooks/payment';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { Box } from '@components/Box';
import { CajaWrapper } from '../../components';
import { useTrackCajaEvent } from '../../events';
import { useFormValidator } from '../../hooks/useFormValidator';
import { findFirstPreviousInvalidStep } from '../../stepValidation/findFirstPreviousInvalidStep';
import { getSteps } from './getSteps';
import { CreateAccount } from './steps/createAccount';
import { CreateBusiness } from './steps/createBusiness';
import { getSteps as getPostJobSteps } from './steps/postJob/getSteps';
import { PostJob as MultiStepJobPost } from './steps/postJob/postJob';
import { PostJobSuccess } from './steps/postJobSuccess';
import { UpgradeJob } from './steps/upgradeJob';
import { VerifyEmail } from './steps/verifyEmail';

export function SignUp() {
  useUpdateSourceQuery();

  const [createdJobId, setJobId] = useState<string | null>();
  const [businessCountryCode, setCountryCode] = useBusinessState();

  const { isUpgradeable } = useUpgradePermissions(businessCountryCode!!);

  const {
    steps,
    currentStep,
    currentStepIndex,
    gotoNextStep,
    stepInfo,
    stepsToStepIds,
    gotoSpecificStep
  } = useSteps<any>({
    data: {},
    getSteps: () => getSteps({ isUpgradeable })
  });

  const history = useHistory();

  // BAD hack for when the user clicks 'submit' on the job description step
  // Then go to 'upgradeJob' step and click the browser back button
  // They think they are adding a description to the job they just created, but they aren't,
  // A new draft is created, then they submit that and it borks the submit (as title etc are required)
  if (currentStep.id !== 'upgradeJob' && createdJobId) {
    const urlFirstPart = history.location.pathname.split('/')[1];
    history.push(
      `/${urlFirstPart}/jobs/${createdJobId}/edit-job/review-job-description`
    );
  }

  useTrackCajaEvent({
    step: currentStep.id,
    variant: formVariant.formVariant,
    jobId: createdJobId || undefined
  });

  const formValidator = useFormValidator();

  async function arePreviousStepsValid(data: Partial<PostJobFormData>) {
    const firstInvalidStep = await findFirstPreviousInvalidStep({
      allSteps: steps,
      currentStepIndex,
      data,
      validator: formValidator
    });
    if (firstInvalidStep) gotoSpecificStep(firstInvalidStep);
  }

  const onPostJobLoad = (data: Partial<PostJobFormData>) => {
    const dataCountryCode = data.business?.geolocation.countryCode;
    setCountryCode(dataCountryCode);
    arePreviousStepsValid(data);
  };

  const onPostJobNextStep = (data: Partial<PostJobFormData>) => {
    const dataCountryCode = data.business?.geolocation.countryCode;
    setCountryCode(dataCountryCode);
  };

  const onPostJobCompleted = (data: Partial<PostJobFormData>, { jobId }) => {
    setJobId(jobId);
    gotoNextStep(data);
  };

  const postJobSteps = getPostJobSteps({});

  return (
    <CajaWrapper
      title={currentStep.pageTitle}
      step={{
        index: currentStepIndex,
        title: currentStep.title,
        total: steps.length
      }}
    >
      <Box padding="small">
        {currentStep.id === 'createAccount' && (
          <CreateAccount
            submitBtnText={stepInfo.submitText}
            onSubmit={gotoNextStep}
          />
        )}
        {currentStep.id === 'verifyEmail' && (
          <VerifyEmail
            submitBtnText={stepInfo.submitText}
            onSubmit={gotoNextStep}
          />
        )}
        {currentStep.id === 'createBusiness' && (
          <CreateBusiness
            submitBtnText={stepInfo.submitText}
            onSubmit={gotoNextStep}
          />
        )}
        {stepsToStepIds(postJobSteps).includes(currentStep.id) && (
          <MultiStepJobPost
            onNext={onPostJobNextStep}
            onCompleted={onPostJobCompleted}
            onLoad={onPostJobLoad}
          />
        )}
        {currentStep.id === 'upgradeJob' && createdJobId && (
          <UpgradeJob
            jobId={createdJobId!!}
            jobCountryCode={businessCountryCode!!}
          />
        )}
        {currentStep.id === 'postJobSuccess' && createdJobId && (
          <PostJobSuccess jobId={createdJobId!!} />
        )}
      </Box>
    </CajaWrapper>
  );
}
