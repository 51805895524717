import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Job } from '@common/types';
import { Text } from '@components/Text';
import useJobSummary from './hooks/useJobSummary';

type JobSummaryProps = {
  job: Job;
};

export const JobSummary = ({ job }: JobSummaryProps) => {
  const { t } = useTranslation();
  const summary = useJobSummary(job);

  return (
    <div>
      <div className="mt-5">
        <Text weight="regular">{t('postJobForm.summary.title')}</Text>
      </div>

      <ul className="list-disc ml-10 font-light">
        <li>
          <Text>{summary.workExperience}</Text>
        </li>
        {summary.isSalaryDefined && (
          <li>
            <Text>{summary.salary}</Text>
          </li>
        )}
        <li>
          <Text>{t(`postJobForm.summary.jobType.${job.jobType}`)}</Text>
        </li>
        <li>
          <div>
            <Text>{t('postJobForm.summary.shiftAvailability.title')}</Text>
          </div>
          <ul className="list-disc ml-10">
            {summary.isShiftTypeAnyTime && (
              <li>
                <Text>
                  {t('postJobForm.summary.shiftAvailability.anyTime')}
                </Text>
              </li>
            )}
            {summary.isShiftTypeSpecific && summary.shiftAvailability}
          </ul>
        </li>
        <li>
          <Text>
            {job.rightToWork
              ? t('postJobForm.summary.noRequiredRightToWork')
              : t('postJobForm.summary.rightToWork')}
          </Text>
        </li>
        {job.immediateStart && (
          <li>
            <Text>{t('postJobForm.summary.immediateStart')}</Text>
          </li>
        )}
      </ul>
    </div>
  );
};
