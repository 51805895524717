import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { RequestErrorMessages } from '@common/components/RequestErrorMessage';
import { AdUpgradePanel } from '@common/components/upgradeJobAd';
import { useGetJobPostedSuccessLink } from '@common/hooks/appPageLinks';
import { useGetPaymentSuccessLink } from '@common/hooks/appPageLinks/useGetPaymentSuccessLink';
import { useLoadJobAndAdPrices } from '@common/hooks/payment';
import { useRedirectToJobNotFoundIfError } from '@common/hooks/useRedirectToJobNotFoundIfError';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useRedirectUnupgradeableAd } from '@common/hooks/useRedirectUnupgradeableAd';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { useGetRequestErrors } from './hooks/useGetRequestErrors';

export const UpgradeJob = () => {
  const { t } = useTranslation();
  const { requestKeys } = useGetRequestErrors();
  const { jobId } = useParams<{ jobId: string }>();

  const { checkQueryParam, getQueryParam } = useSearchQueryParam();
  const fromPostJobFlow = checkQueryParam('fromPostJob');
  const campaign = getQueryParam('campaign') as string;
  const basicRedirectLink = useGetJobPostedSuccessLink(jobId);

  useRedirectUnauthenticatedUser();
  useRedirectToJobNotFoundIfError();

  const { job, jobCountryCode } = useLoadJobAndAdPrices(jobId);
  useRedirectUnupgradeableAd(job);
  const getPaymentSuccessLink = useGetPaymentSuccessLink({
    jobId,
    jobCountryCode,
    fromPostJob: true
  });

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('upgradeJobPage.title')} />

      <Box
        paddingTop="medium"
        paddingBottom="small"
        paddingHorizontal="medium"
        tone="transparent"
      >
        <Heading level="h1" weight="regular">
          {t('upgradeJobPage.heading')}
        </Heading>
      </Box>

      <Card>
        <CardContent paddingBottom="small">
          {job ? (
            <AdUpgradePanel
              job={{
                id: jobId,
                countryCode: jobCountryCode
              }}
              basicAdLink={fromPostJobFlow ? basicRedirectLink : undefined}
              campaign={campaign}
              getPaymentSuccessLink={getPaymentSuccessLink}
            />
          ) : null}

          <RequestErrorMessages requestKeys={requestKeys} />
        </CardContent>
      </Card>
    </Stack>
  );
};
